import React from 'react'
import {Link} from 'gatsby'
import logo from "../../assets/images/logo.png"
import footerMap from "../../assets/images/footer-map.png"

const Footer = ({ designRushBlue, designRushBlack }) => {

    const currentYear = new Date().getFullYear();
       
    return (
        <footer className="footer-area bg-color">
            <div className="container">
                <div className="row">
                    <div className="col-lg-4 col-sm-6">
                    <div className="single-footer-widget">
                            <a href="/" className="logo">
                                <img src={logo} alt="logo" />
                            </a>
                            <p>A company with a mission to transform the world with innovative solutions enabling delivery of quality products independent of the human development index.</p>

                            <ul className="social-link">
                                <li>
                                    <a href="https://www.facebook.com/zaavia.net" className="d-block" target="_blank" rel="noreferrer" aria-label='facebook'>
                                        <i className='bx bxl-facebook'></i>
                                    </a>
                                </li>
                                <li>
                                    <a href="https://www.linkedin.com/company/zaavia" className="d-block" target="_blank" rel="noreferrer" aria-label='linkedin'>
                                        <i className='bx bxl-linkedin'></i>
                                    </a>
                                </li>
                                <li>
                                    <a href="https://www.instagram.com/world.of.zaavia/" className="d-block" target="_blank" rel="noreferrer" aria-label='instagram'>
                                        <i className='bx bxl-instagram'></i>
                                    </a>
                                </li>
                                <li>
                                    <a href="https://www.youtube.com/@ZaaviaSoftwareHouse" className="d-block" target="_blank" rel="noreferrer" aria-label='youtube'>
                                        <i className='bx bxl-youtube'></i>
                                    </a>
                                </li>
                            </ul>
                        </div>
                    </div>

                    <div className="col-lg-2 col-sm-6">
                        <div className="single-footer-widget pl-5">
                            <h5>Explore</h5>
                            
                            <ul className="footer-links-list">
                                <li>
                                    <Link to="/">
                                        Home
                                    </Link>
                                </li>
                                    <li>
                                    <Link to="/about-us">
                                        About
                                    </Link>
                                </li>
                                <li>
                                    <Link to="/contact">
                                        Contact
                                    </Link>
                                </li>
                                   <li>
                                    <Link to="/privacy-policy">
                                        Privacy Policy
                                    </Link>
                                </li>
                            </ul>
                        </div>
                    </div>
               
                    <div className="col-lg-2 col-sm-6">
                        <div className="single-footer-widget">
                            <h5>Resources</h5>

                            <ul className="footer-links-list">
                            <li>
                                    <Link to="/products">
                                        Our Products
                                    </Link>
                                </li>
                                <li>
                                    <Link to="/service-details">
                                        Our Services
                                    </Link>
                                </li>
                                <li>
                                    <Link to="/blogs/">
                                        Blogs
                                    </Link>
                                </li>
                            </ul>
                        </div>
                    </div>

                    <div className="col-lg-4 col-sm-6">
                        <div className="single-footer-widget">
                            <h5>Address</h5>

                            <ul className="footer-contact-info">
                                <li>
                                    <i className='bx bx-map'></i> 
                                    29-1/B Khayaban-e-Shaheen, D.H.A Phase V Defence Housing Authority, Karachi City, Sindh 75500
                                </li>
                                <li>
                                    <i className='bx bx-phone-call'></i>
                                    <a href="tel:03347262232">03347262232</a>

                                </li>
                                <li>
                                    <i className='bx bx-envelope'></i>
                                    <a href="mailto:info@zaavia.net">info@zaavia.net</a>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>

                <div className="footer-bottom-area">
                    { (designRushBlue && designRushBlack) && 
                        <div>
                            <a href=" https://www.designrush.com/agency/profile/zaavia" target='_blank' rel='noreferrer' className='footer-logo mr-3' >
                                <img src={designRushBlack} alt="DesignRush" />
                            </a>
                            <a href="https://www.designrush.com" target='_blank' rel='noreferrer' className='footer-logo' >
                                <img src={designRushBlue} alt="DesignRush" />
                            </a>
                        </div>
                    }
                    <div className="row align-items-center footer-copyright">
                        <div className="col-lg-6 col-md-6">
                            <p>Copyright @ {currentYear} <strong>Zaavia</strong> All rights reserved </p>
                        </div>
                    </div>
                </div>
            </div>

            <div className="footer-map">
                <img src={footerMap} alt="footer-logo" />
            </div>
        </footer>
    );
}

export default Footer;